import AnimatedText from "components/ui/AnimatedText";
import { useEffect } from "react";
import { BiRocket, BiUser } from "react-icons/bi";
import useAuthStore from "store/auth";

function LoginSelect() {
	const { setLoginType, settings } = useAuthStore();

	return (
		<>
			<AnimatedText>
				<h1 className="text-center text-blue-dark text-7xl font-bold">
					IDENTIFICAÇÃO
					<br />
					DA COOPERATIVA
				</h1>
			</AnimatedText>
			<div
				className={`flex-1 gap-16 grid ${
					settings?.is_web ? "grid-cols-auto" : "grid-cols-2"
				}  p-16 animate-in slide-in-from-bottom-5`}
			>
				{!settings?.is_web ? (
					<button
						onClick={() => setLoginType("fast-pass")}
						type="button"
						className="bg-white text-blue-dark gap-4 rounded-xl flex flex-col items-center justify-center"
					>
						<h2 className="text-5xl font-bold">
							FAST-PASS
							<br />
							(CÓDIGO DE FILA)
						</h2>
						<BiRocket size={256} />
					</button>
				) : null}
				<button
					onClick={() => setLoginType("register")}
					type="button"
					className="bg-blue-dark text-white gap-4 rounded-xl flex flex-col items-center justify-center"
				>
					<h2 className="text-5xl font-bold">
						REALIZAR
						<br />
						CADASTRO
					</h2>
					<BiUser size={256} />
				</button>
			</div>
		</>
	);
}

export default LoginSelect;
