import { create } from "zustand";

type AuthStore = {
  loginType: string | null;
  settings: any;
  setLoginType: (loginType: string) => void;
  setSettings: (settings: any) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  loginType: null,
  settings: {},
  setLoginType: (loginType) => set({ loginType }),
  setSettings: (settings) => {
    set({ settings });
  },
}));

export default useAuthStore;
