import { createContext, ReactNode, useContext, useState } from "react";
import {
	fetchDataCapacita,
	fetchDataCredito,
	fetchDataDemais,
	fetchDataGestao,
	fetchDataInova,
	fetchDataSaude,
	fetchDataSouCoop,
} from "services/jornada.service";
import { formatCNPJ } from "services/utils";

interface CoopContextData {
	coopInfo?: any;
	coop?: any[];
	coopAtualizada?: boolean;
	pdgc?: any[];
	negocios?: any[];
	categoria?: string;
	ranking?: any;
	seloCoop: boolean;
	setCoop: any;
	setCoopInfo: any;
	setPdgc: any;
	setNegocios: any;
	setCategoria: any;
	setRanking: any;
	setCoopAtualizada: any;
	setSeloCoop: any;
	loadCoopData: (cnpj: string) => void;
	reset: () => void;

	// NOVOS INDICADORES
	desempenho: any;
	governancaGestao: any;
	capacitaCoop: any;
	inovaCoop: any;
}

interface CoopProviderProps {
	children: ReactNode;
}

const CoopContext = createContext({} as CoopContextData);

function CoopProvider({ children }: CoopProviderProps) {
	const [categoria, setCategoria] = useState();
	const [coopInfo, setCoopInfo] = useState();
	const [coopAtualizada, setCoopAtualizada] = useState(false);
	const [seloCoop, setSeloCoop] = useState(false);
	const [coop, setCoop] = useState();
	const [pdgc, setPdgc] = useState();
	const [negocios, setNegocios] = useState();
	const [ranking, setRanking] = useState(0);

	// NOVO INDICADORES
	const [desempenho, setDesempenho] = useState();
	const [governancaGestao, setGovernancaGestao] = useState();
	const [capacitaCoop, setCapacitaCoop] = useState();
	const [inovaCoop, setInovaCoop] = useState();

	function reset() {
		setCoop(undefined);
		setCoopInfo(undefined);
		setCoopAtualizada(false);
		setSeloCoop(false);
		setPdgc(undefined);
		setNegocios(undefined);
		setDesempenho(undefined);
		setGovernancaGestao(undefined);
		setCapacitaCoop(undefined);
		setInovaCoop(undefined);
	}

	const loadCoopData = async (cnpj: string) => {
		try {
			console.log("loadCoopData", cnpj);
			if (!cnpj) return;

			const response = await fetchDataSouCoop(formatCNPJ(cnpj));

			const ramo = response.ramo;

			if (ramo === "CRÉDITO") {
				const responseDesempenho = await fetchDataCredito(cnpj);
				// console.log("responseDesempenho", ramo, responseDesempenho);
				setDesempenho(responseDesempenho);
			} else if (ramo === "SAÚDE") {
				const responseDesempenho = await fetchDataSaude(cnpj);
				// console.log("responseDesempenho", ramo, responseDesempenho);
				setDesempenho(responseDesempenho);
			} else {
				const responseDesempenho = await fetchDataDemais(cnpj);
				// console.log("responseDesempenho", ramo, responseDesempenho);
				setDesempenho(responseDesempenho);
			}

			const dataGovernancaGestao = await fetchDataGestao(formatCNPJ(cnpj));
			const dataCapacita = await fetchDataCapacita(formatCNPJ(cnpj));
			const dataInova = await fetchDataInova(formatCNPJ(cnpj));

			// console.log("dataGovernancaGestao", dataGovernancaGestao);
			// console.log("dataCapacita", dataCapacita);
			// console.log("dataInova", dataInova);

			setCoopInfo(response);
			setGovernancaGestao(dataGovernancaGestao);
			setInovaCoop(dataInova);
			setCapacitaCoop(dataCapacita);
		} catch (error) {
			console.log(error);
		}
	};

	const value = {
		coopInfo,
		coop,
		coopAtualizada,
		pdgc,
		negocios,
		categoria,
		ranking,
		seloCoop,
		setSeloCoop,
		setCoopAtualizada,
		setRanking,
		setCategoria,
		setCoop,
		setCoopInfo,
		setPdgc,
		setNegocios,
		loadCoopData,
		reset,

		// NOVOS INDICADORES
		desempenho,
		governancaGestao,
		capacitaCoop,
		inovaCoop,
	};

	return <CoopContext.Provider value={value}>{children}</CoopContext.Provider>;
}

function useCoop() {
	const context = useContext(CoopContext);
	if (context === undefined) {
		throw new Error("useCoop must be used within a CoopProvider");
	}
	return context;
}

export { CoopProvider, useCoop };
