import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingModal } from "components/modal/LoadingModal";
import Button from "components/ui/Button";
import { SignUpCredentials, useAuth } from "context/AuthContext";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Keyboard, { KeyboardHandlerEvent } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { toast } from "react-toastify";
import { inputClass } from "styles";
import * as yup from "yup";
import "../styles.scss";

import { searchCooperado } from "services/jornada.service";
import { formatCNPJ } from "services/utils";
import { IFormInputs } from "types/login";
import { cnpjValidation, cpfValidation, validateEmail } from "utils/helpers";
import Back from "components/ui/Back";
import Terms from "components/ui/Terms";
import useAuthStore from "store/auth";

const registerSchema = yup.object({
	search: yup.string(),
	cnpj: yup.string().length(18).required("COOP Inválida"),
	email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
	// cpf: yup.string().required("CPF obrigatório"),
	cpf: yup.string(),
	name: yup.string().required("Você não digitou seu nome"),
});

const defaultKeyboardLayout = {
	default: [
		"q w e r t y u i o p {bksp}",
		"a s d f g h j k l {enter}",
		"{shift} z x c v b n m , . {shift}",
		"{alt} {space} @ {altright}",
	],
	email: [
		"q w e r t y u i o p {bksp}",
		"a s d f g h j k l {enter}",
		"{shift} z x c v b n m , . {shift}",
		"{alt} {space} @ {altright}",
	],
	shift: [
		"Q W E R T Y U I O P {bksp}",
		"A S D F G H J K L {enter}",
		"{shiftactivated} Z X C V B N M , . {shiftactivated}",
		"{alt} {space} @ {altright}",
	],
	alt: [
		"1 2 3 4 5 6 7 8 9 0 {bksp}",
		`@ # $ & * ( ) ' " {enter}`,
		"{shift} % _ - + = / ; : ! ? {shift}",
		"{default} {space} {altright}",
	],
};

type LoginRegisterProps = {
	onAuthSuccess: () => void;
};
export default function LoginRegister({ onAuthSuccess }: LoginRegisterProps) {
	const navigate = useNavigate();

	const { settings } = useAuthStore();
	const { login, signUp } = useAuth();
	const [loading, setLoading] = useState(false);
	const [formStep, setFormStep] = useState(0);
	const [keyboardLayout, setKeyboardLayout] = useState("default");
	const [confirmLgpd, setConfirmLgpd] = useState(false);
	const [allowNewsletter, setAllowNewsletter] = useState(true);
	const keyboardRef = useRef(null) as MutableRefObject<KeyboardHandlerEvent>;

	const [selectedCoop, setSelectedCoop] = useState<any>();

	const [searchResults, setSearchResults] = useState<any[]>([]);

	const [confirmCoop, setConfirmCoop] = useState(false);

	const {
		register,
		handleSubmit,
		clearErrors,
		setError,
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useForm<IFormInputs>({
		resolver: yupResolver(registerSchema),
		reValidateMode: "onChange",
	});

	const cnpjValue = watch("cnpj");

	const onRegister = async (data: IFormInputs) => {
		clearErrors();
		setLoading(true);

		const payload = {
			...data,
			cnpj: `${parseInt(data.cnpj?.replace(/\D/g, "") || "0")}`,
			newsletter: allowNewsletter,
		};

		try {
			const result: any = await signUp(payload as SignUpCredentials);
			await login({
				username: `${result._id}`,
				password: `JornadaCoop@2024#!`,
			});

			setTimeout(() => {
				onAuthSuccess();
			}, 1000);

			// setTimeout(() => {
			// 	navigate("/passo/1");
			// }, 1000);

			// navigate("/passo/1");
			// setRegisterUser(data);
		} catch (err: any) {
			// setError("name", { message: err });

			setLoading(false);

			toast(
				`Dados inválidos! Por favor revise o CNPJ, e-mail e nome informados.`,
				{
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);

			//@todo tratar NOME já existentes
		}

		// const cleanName : string = data?.email?.replace("/[^a-zA-Z0-9]/", "");
		// login({username: `${data.email}${cleanName}`, password: `${data.cnpj}`});

		// setLoading(true);

		// setTimeout(()=>{
		// 	navigate("/passo/1");
		// });
	};

	const onChange = (e: any, a: any) => {
		// console.log("onChange", e, a);

		clearErrors();

		if (!e && a.target.innerText === "ENTER") return;

		switch (formStep) {
			case 0:
				setValue("search", e.toUpperCase());

				// console.log(inputMaskRef.current);
				// inputMaskRef.current.inputValue = cnpj;
				// document.getElementById("cnpj")?.focus();

				if (e.length > 3) {
					searchCoop(e.toUpperCase(), (result: any[]) =>
						setSearchResults(result)
					);
				}

				break;
			case 1:
				setValue("name", e.toUpperCase());
				break;
			case 2:
				setValue("email", e);
				break;
			case 3:
				setValue("cpf", e);
				break;
			default:
				break;
		}
	};

	const onKeyPress = (e: any) => {
		if (e === "{enter}") {
			nextStep();
		} else if (e === "{alt}" || e === "{altright}") {
			setKeyboardLayout("alt");
		} else if (e === "{shift}") {
			setKeyboardLayout("shift");
		} else if (e === "{default}" || e === "{shiftactivated}") {
			setKeyboardLayout("default");
		} else if (e === "{back}") {
			nextStep();
		}
	};

	function nextStep() {
		clearErrors();

		setValue("search", "");

		if (keyboardRef.current) {
			keyboardRef.current.clearInput("keyboard");
		}

		// console.log(getValues());

		if (formStep === 0) {
			if (!cnpjValidation(getValues("cnpj"))) {
				setError("cnpj", { message: "CNPJ inválido" });
				return;
			}

			setFormStep(formStep + 1);

			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
			return;
		} else if (formStep === 1) {
			const name = getValues("name");
			if (!name || name.length < 3) {
				setError("name", { message: "Nome inválido" });
				return;
			}
		} else if (formStep === 2) {
			const email = getValues("email") || "";
			if (!validateEmail(email)) {
				setError("email", { message: "E-mail inválido" });
				return;
			}
		} else if (formStep === 3) {
			const cpf = getValues("cpf") || "";
			if (!cpfValidation(cpf)) {
				setError("cpf", { message: "CPF inválido" });
				return;
			}
		}

		if (formStep === 5) {
			setLoading(true);
		} else {
			if (settings?.is_web && formStep === 2) {
				setFormStep(formStep + 2);
			} else {
				setFormStep(formStep + 1);
			}
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}

	function backStep() {
		if (formStep === 0) {
			navigate("../");
		} else {
			if (settings?.is_web && formStep === 4) {
				setFormStep(formStep - 2);
			} else {
				setFormStep(formStep - 1);
			}
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}

	function showLgpd() {
		setFormStep(5);
	}

	useEffect(() => {
		if (keyboardRef.current) {
			keyboardRef.current.clearInput("keyboard");
		}

		setKeyboardLayout("email");

		if (formStep === 1) {
			setValue("name", "");
		} else if (formStep === 2) {
			setValue("email", "");
			setKeyboardLayout("email");
		}

		// if (formStep ) {
		// 	setKeyboardLayout("email");
		// } else {
		// 	setKeyboardLayout("default");
		// }
	}, [formStep]);

	const searchCoop = (
		inputValue: string,
		callback: (options: any[]) => void
	) => {
		try {
			clearErrors();
			if (inputValue.length >= 3) {
				searchCooperado(inputValue).then((result) => {
					const options = result.map((item: any) => {
						return {
							value: formatCNPJ(item.cnpj),
							label: `${formatCNPJ(item.cnpj)} - ${item.nome_fantasia}`,
							data: item,
						};
					});
					callback(options);
				});
			} else {
				callback([]);
			}
			// console.log(result);
			// const options = result.map((item: any) => {
			// 	return {
			// 		value: item.cnpj,
			// 		label: `${item.cnpj} - ${item.nome_fantasia}`,
			// 	};
			// });
			// setCnpjOptions(options);
			// setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		try {
			// searchCoop();
		} catch (err) {
			setLoading(false);
		}
	}, [searchCoop]);

	if (loading) {
		return <LoadingModal />;
	}

	return (
		<>
			<Back className="absolute top-32 left-44" onClick={() => backStep()} />

			<div
				className={`mx-auto my-16`}
				style={{
					width: formStep < 4 ? `${1200}px` : `${1200}px`,
					minHeight: formStep === 0 ? `${850}px` : `${850}px`,
				}}
			>
				<form
					onSubmit={handleSubmit(onRegister)}
					className="flex flex-col justify-center text-xl text-blue-dark"
				>
					<div className="space-y-8">
						{formStep === 0 && (
							<label htmlFor="cnpj" className="block text-4xl text-center">
								<span className="text-5xl font-bold">ENCONTRE SUA COOP</span>
								<br />
								Digite o CNPJ ou NOME da cooperativa
							</label>
						)}

						{formStep === 1 && (
							<label htmlFor="name" className="block text-4xl text-center">
								<span className="text-5xl font-bold">SOBRE VOCÊ</span>
								<br />
								Digite o seu nome
							</label>
						)}

						{formStep === 2 && (
							<label htmlFor="email" className="block text-4xl text-center">
								<span className="text-5xl font-bold">SOBRE VOCÊ</span>
								<br />
								Digite o seu email corporativo
							</label>
						)}

						{formStep === 3 && (
							<label htmlFor="email" className="block text-4xl text-center">
								<span className="text-5xl font-bold">SOBRE VOCÊ</span>
								<br />
								Digite o seu CPF
							</label>
						)}

						{formStep === 4 && (
							<p className="block text-5xl text-center">
								<span className="text-5xl font-bold">TUDO CERTO?</span>
								<br />
								Por favor, confirme seus dados
							</p>
						)}

						{formStep === 5 && (
							<p className="block text-5xl text-center">
								Termos de Uso e Privacidade
							</p>
						)}

						{confirmCoop && formStep === 0 && (
							<div className="bg-white rounded-xl p-10 border-4 border-blue-dark">
								<div className="flex flex-col text-center gap-10 items-center justify-between">
									<p className="text-4xl font-bold text-brand-purple">
										Você deseja realizar a Jornada com esta cooperativa?
									</p>
									<p className="text-5xl">{selectedCoop}</p>
									<div className="flex items-center gap-10">
										<Button
											type="button"
											className="w-[300px] py-4 text-3xl disabled:opacity-50 bg-red-500"
											onClick={() => {
												setConfirmCoop(false);
											}}
										>
											NÃO
										</Button>
										<Button
											type="button"
											className="w-[300px] py-4 text-3xl disabled:opacity-50"
											onClick={() => {
												setConfirmCoop(false);
												nextStep();
											}}
										>
											SIM
										</Button>
									</div>
								</div>
							</div>
						)}

						{!confirmCoop && formStep === 0 && (
							<div>
								<input
									id="search"
									type="text"
									{...register("search")}
									className={`${inputClass} pointer-events-none`}
									placeholder="Digite o CNPJ ou NOME de sua Coop..."
								/>
								{errors.search?.message && (
									<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
										{errors.search?.message}
									</p>
								)}

								<div className="bg-white h-[325px] mt-3 rounded-xl p-2 border-4 border-blue-dark divide-y overflow-y-auto">
									{searchResults && searchResults.length
										? searchResults.map((item) => (
												<div
													key={item.value}
													className={`p-4 text-3xl ${
														cnpjValue === item.value
															? "bg-blue-700 text-white"
															: ""
													}`}
													onClick={() => {
														setValue("cnpj", item.value);
														if (item.data) {
															setSelectedCoop(item.data.nome_fantasia);
															setConfirmCoop(true);
														}
													}}
												>
													{item.label}
												</div>
										  ))
										: null}

									{!searchResults || !searchResults.length ? (
										<div className="p-3 text-3xl">
											{getValues("search")?.length
												? "Nenhuma cooperativa encontrada"
												: "Utilize o teclado abaixo para digitar o CNPJ ou NOME da sua Coop"}
										</div>
									) : null}
								</div>
							</div>
						)}

						{formStep === 1 && (
							<div>
								<input
									id="name"
									type="text"
									{...register("name")}
									className={`${inputClass} pointer-events-none`}
									placeholder="Digite aqui seu nome"
								/>
								{errors.name?.message && (
									<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
										{errors.name?.message}
									</p>
								)}
							</div>
						)}

						{formStep === 2 && (
							<div>
								<input
									id="email"
									type="text"
									{...register("email")}
									className={`${inputClass} pointer-events-none`}
									placeholder="Digite aqui seu email corporativo"
								/>
								{errors.email?.message && (
									<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
										{errors.email?.message}
									</p>
								)}
							</div>
						)}

						{formStep === 3 && (
							<div>
								<input
									id="cpf"
									type="text"
									{...register("cpf")}
									className={`${inputClass} pointer-events-none`}
									placeholder="Digite aqui seu CPF"
								/>
								{errors.cpf?.message && (
									<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
										{errors.cpf?.message}
									</p>
								)}
							</div>
						)}

						{formStep === 4 && (
							<div className="pt-5">
								<div className="grid grid-cols-2 gap-8">
									<div>
										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-4xl font-bold">COOP:</p>
												<span className="text-5xl">{selectedCoop}</span>
											</div>
										</div>

										{/* <hr className="border-blue-dark border-t-4 mt-3 border-dashed" /> */}
									</div>

									<div>
										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-4xl font-bold">NOME:</p>
												<span className="text-5xl">{getValues().name}</span>
											</div>
										</div>

										{/* <hr className="border-blue-dark border-t-4 mt-3 border-dashed" /> */}
									</div>

									<div>
										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-4xl font-bold">E-MAIL:</p>
												<span className="text-5xl">{getValues().email}</span>
											</div>
										</div>

										{/* <hr className="border-blue-dark border-t-4 border-dashed" /> */}
									</div>

									{!settings?.is_web ? (
										<div>
											<div className="flex flex-row items-center justify-between">
												<div>
													<p className="text-4xl font-bold">CPF:</p>
													<span className="text-5xl">{getValues().cpf}</span>
												</div>
											</div>
										</div>
									) : null}
								</div>

								<div className="mt-10 text-2xl">
									<input
										id="agreement-lgdp"
										name="agreement-lgdp"
										type="checkbox"
										className="mr-3 w-8 h-8 border border-blue-800"
										onChange={(e) => setConfirmLgpd(e.target.checked)}
									/>{" "}
									<label htmlFor="agreement-lgdp">
										Li e estou de acordo com os tratamentos dos dados pessoais
										conforme indicado no{" "}
										<span
											onClick={() => showLgpd()}
											className="text-blue-900 underline"
										>
											Aviso de Privacidade
										</span>{" "}
										da “Jornada Coop”;
									</label>
								</div>

								<div className="mt-6 text-2xl">
									<input
										id="agreement-newsletter"
										name="agreement-newsletter"
										type="checkbox"
										className="mr-3 w-8 h-8 border border-blue-800"
										onClick={(e) => {
											const checked: HTMLInputElement = e.currentTarget;
											setAllowNewsletter(checked.checked);
										}}
									/>{" "}
									<label htmlFor="agreement-newsletter">
										Desejo receber comunicações sobre o Sistema OCB por e-mail.
									</label>
								</div>
							</div>
						)}

						{formStep === 5 && (
							<div className="flex h-[730px] flex-col">
								<Terms />
							</div>
						)}

						{!confirmCoop && formStep > 0 ? (
							<div className="mt-2 mx-auto w-2/3 pt-3">
								{formStep < 4 && (
									<Button
										type="button"
										className="w-full py-4 text-3xl disabled:opacity-50"
										onClick={() => nextStep()}
									>
										Prosseguir
									</Button>
								)}
								{formStep === 4 && (
									<Button
										type="submit"
										disabled={!confirmLgpd}
										className="w-full disabled:opacity-50 py-6 text-3xl bg-orange-600"
									>
										Estou de acordo com os termos
									</Button>
								)}
							</div>
						) : null}

						{!confirmCoop && formStep < 4 && (
							<div className="p-5 bg-blue-dark rounded-tl-xl rounded-tr-xl absolute w-[1200px] mx-auto left-0 right-0 bottom-0">
								<Keyboard
									disableButtonHold={true}
									layout={defaultKeyboardLayout}
									layoutName={keyboardLayout}
									theme="hg-theme-default hg-theme-ios"
									buttonTheme={[
										{
											class: "hg-space",
											buttons: "{space}",
										},
										{
											class: "hg-alt",
											buttons: "{alt} {altright}",
										},
									]}
									display={{
										"{alt}": ".?123",
										"{smileys}": "\uD83D\uDE03",
										"{shift}": "⇧",
										"{shiftactivated}": "⇧",
										"{enter}": "ENTER",
										"{bksp}": "⌫",
										"{altright}": ".?123",
										"{downkeyboard}": "🞃",
										"{space}": " ",
										"{default}": "ABC",
										"{back}": "⇦",
									}}
									onChange={onChange}
									onKeyPress={onKeyPress}
									inputName="keyboard"
									keyboardRef={(r) => (keyboardRef.current = r)}
								/>
							</div>
						)}
					</div>
				</form>
			</div>
		</>
	);
}
